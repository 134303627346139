.snipes-page {
    padding: 15px;
    padding-bottom: 200px;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}