.bg-snipes-header {
    background-color: rgb(75, 77, 84);
    color: white;

    border-bottom: 2px solid lightgray;
}

.bg-snipes-footer {
    background-color: rgb(75, 77, 84);
    color: white;
}

.white-text {
    color: white !important;
}

.nav-link {
    color: white !important;
}
