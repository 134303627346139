
.icon-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #ec640c;
}

.icon {
    font-size: 48px;
    margin-bottom: 10px;
}


.imageContainer {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-body {
    min-height: 38px;
}

.cardImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
    max-height: 100%;
}

.card-footer {
    text-align: center;
    min-height: 40px;
}