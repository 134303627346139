.nav-link {
    color: white !important;
}
.nav-link[aria-selected="true"] {
    background-color: rgb(75, 77, 84) !important;
    text-decoration: underline;
    color: lightblue !important;
}

.admin-panel-content {
    background-color: lightslategray;
}

.admin-panel-nav .nav-link[aria-selected="true"] {
    background-color: orange !important;
    text-decoration: underline;
    color: lightblue !important;
}
