.error-message {
    color: red;
}

.alert-bottom-right {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.search-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f4f4f4;
    align-items: stretch;
}

.search-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: calc(50% - 20px);
    margin: 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.search-card p {
    margin: 10px 0;
    min-height: 25px;
    color: #333;
    font-family: Arial, sans-serif;
}

.search-card br {
    min-height: 35px;
}

.search-card p:first-child {
    font-weight: bold;
    color: #d9534f;
}

.search-card p:nth-child(2) {
    color: #5bc0de;
}

.search-card .search-card-products {
    flex-grow: 1;
}
.search-card .search-card-url {
    min-height: 50px;
}

.pagination-element {
    flex: 1 0 9.09%;
}
