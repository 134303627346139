.legendErrorName {
    min-width: 30%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.legend {
    display: flex;
    flex-direction: column;
}

.legendElement {
    display: flex;
    margin: 10px;
}